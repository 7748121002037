<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="premium-banner">
    <InfinitySignBanner
      text-color="#fff"
      background-color="#771d31"
      icon-color="#cbb762"
      fluid
    >
      <div class="container">
        <slot
          name="content"
          :upgrade-to-premium="upgradeToPremium"
          :is-requesting-upgrade="isRequestingUpgrade"
        >
          <div class="row d-flex justify-content-center">
            <div class="col-12 col-md-6 col-lg-7 pr-0">
              <h3 class="mb-16">
                {{ $label('banners.premiumBanner.title') }}
              </h3>
              <p class="mb-24">
                {{ $label('banners.premiumBanner.text') }}
              </p>

              <BaseButton
                :class="[{ loading: isRequestingUpgrade }]"
                variant="call-to-action"
                class="mb-24"
                @click="upgradeToPremium"
              >
                {{ $label('banners.premiumBanner.btnText') }}
              </BaseButton>

              <p class="text-small fst-italic">
                {{ $label('banners.premiumBanner.caption') }}
              </p>
            </div>

            <div
              class="col-12 col-md-6 col-lg-4 d-flex flex-column align-items-md-end"
            >
              <div class="mt-48 mt-md-0">
                <h3 class="mb-16">
                  {{ $label('banners.premiumBanner.uspsTitle') }}
                </h3>
                <ul
                  class="styled-list text-start"
                  v-html="$label('banners.premiumBanner.uspsList')"
                />
              </div>
            </div>
          </div>
        </slot>
      </div>
    </InfinitySignBanner>
  </div>
</template>

<script>
import InfinitySignBanner from '~/components/banners/InfinitySignBanner'
import SubscriptionMixin from '~/mixins/subscription'

export default {
  components: {
    InfinitySignBanner,
  },
  mixins: [SubscriptionMixin],
}
</script>

<style lang="scss" scoped>
.premium-banner {
  background-color: $red-800;
}
</style>
